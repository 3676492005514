import {
    Button,
    message,
    Spin
} from "antd";
import React, { useEffect, useState } from "react";
import {API} from "../../constant";
import {getToken} from "../../helpers";
import {Excalidraw, exportToBlob} from "@excalidraw/excalidraw";
import {ExcalidrawAPIRefValue} from "@excalidraw/excalidraw/types/types";
import {useParams} from "react-router-dom";
import {FileDataMeta} from "./FileSystemModel";
import {ExcalidrawElement, NonDeletedExcalidrawElement} from "@excalidraw/excalidraw/types/element/types";
import {Helmet} from "react-helmet";
import {useAuthContext} from "../../context/AuthContext";

const ExcalidrawEdit = () => {
    const userContext= useAuthContext();
    const { id } = useParams();
    const [file, setFile] = useState<FileDataMeta | undefined>(undefined);
    const [idFile, setIdFile] = useState<bigint | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawAPIRefValue | null>(null);

    const fetchFile = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/file-systems/${id}?populate=image`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data: FileDataMeta = await response.json();
            setFile(data ?? undefined);

            const title: string = data.data.attributes.name;
            userContext.setTitle(title);

            setIdFile(data.data.attributes.image?.data?.id)
            console.log("Image associée : " + data.data.attributes.image?.data?.id);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false)
    };

    const updateFile = async (elt: readonly NonDeletedExcalidrawElement[]) => {
        setIsLoading(true);
        if (!file) return
        try {
            const idFileToRemove = idFile;
            console.log("idFileToRemove : " + idFileToRemove)
            file.data.attributes.data = elt;
            if (file.data.attributes.image===undefined) {
                file.data.attributes.image = {data: null};
            } else {
                file.data.attributes.image.data = null;
            }

            const img = await exportToBlob({
                elements: elt,
                appState: { exportWithDarkMode: false},
                files: null,
                mimeType: "image/jpeg",
                quality: 0.92,
                exportPadding: 10
            })
            const formData = new FormData();
            formData.append("files.image", img);
            formData.append('data', JSON.stringify({
                "name": file.data.attributes.name,
                "data": file.data.attributes.data
            }));
            const responseUpload = await fetch(`${API}/file-systems/${id}?populate=image`, {
                method: "PUT",
                body: formData
            })
            const body = await responseUpload.json()
            const newIdFile: bigint = body.data.attributes.image.data.id
            setIdFile(newIdFile)
            console.log("New file uploaded idFile => " + newIdFile);

            if (idFileToRemove !== undefined) {
                const response = await fetch(`${API}/upload/files/${idFileToRemove}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        //Authorization: `Bearer ${getToken()}`,
                    }
                });
            }

            const staticLink = API + "/file-mgt/" + id;
            await navigator.clipboard.writeText(staticLink);
        } catch (error) {
            console.error(error);
            message.error("Error while updating file");
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchFile();
    }, []);

    if (isLoading) {
        return (<Spin size="large" tip="Loading or Saving...">
            <div className="content"/>
        </Spin>);
    }

    const elements: ExcalidrawElement[] | null = file?.data.attributes.data
    const title: string = file?.data.attributes.name || "";
    return (
        <div>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div style={{ height: "calc(100vh - 64px)" }}>
                <Excalidraw
                    initialData={{
                        elements,
                        scrollToContent: true,
                    }}
                    ref={(api) => setExcalidrawAPI(api)}
                    renderTopRightUI={() => {
                        return (
                            <Button style={{ height: "40px"}} onClick={async () => {
                                if (!excalidrawAPI) {
                                    return
                                }
                                if (excalidrawAPI.ready) {
                                    const elements: readonly NonDeletedExcalidrawElement[] = excalidrawAPI.getSceneElements();
                                    await updateFile(elements)
                                    // console.log("Elements : " + JSON.stringify(elements))
                                    if (!elements || !elements.length) {
                                        return
                                    }
                                } else {
                                    return
                                }
                            }}>Save and copy</Button>
                        )
                    }}
                />
            </div>
        </div>
    );
};

export default ExcalidrawEdit;
