import { createContext, useContext } from "react";

export interface UserContextType {
  user: undefined | any
  isLoading: boolean
  setUser: any
  title: string | undefined
  setTitle: any
}

export const AuthContext = createContext<UserContextType>({
  user: undefined,
  isLoading: false,
  setUser: () => {},
  title: undefined,
  setTitle: () => {},
});

export const useAuthContext = () => useContext<UserContextType>(AuthContext);
