import {
    Button,
    Card,
    Col,
    Image,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { getToken } from "../../helpers";

import {
    AiFillTwitterCircle,
    AiFillLinkedin,
    AiFillGithub,
} from "react-icons/ai";

import { CgWebsite } from "react-icons/cg";
import { SiGmail } from "react-icons/si";
import { API, AVATAR_API } from "../../constant";
import {Link} from "react-router-dom";

const Home = () => {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchFiles = async () => {
        setIsLoading(true);
        // try {
        //     const response = await fetch(`${API}/users`);
        //     const data = await response.json();
        //     setFiles(data ?? []);
        // } catch (error) {
        //     console.error(error);
        //     message.error("Error while fetching profiles!");
        // } finally {
        //     setIsLoading(false);
        // }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchFiles();
    }, []);

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <>
            <h1>Bienvenue</h1>
            {getToken() ? (<h2><Link to="/fs">Accéder aux fichiers</Link></h2>): (<h2>Veuillez vous identifier</h2>)}
        </>
    );
};

export default Home;
