import React, { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { message } from "antd";
import { API, BEARER } from "../../constant";
import { useEffect } from "react";
import { getToken } from "../../helpers";

const AuthProvider = (data : any) => {
  const [userData, setUserData] = useState();
  const [titleData, setTitleData] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const authToken = getToken();

  const fetchLoggedInUser = async (token: any) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const data = await response.json();

      setUserData(data);
    } catch (error) {
      console.error(error);
      message.error("Error While Getting Logged In User Details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user: any) => {
    setUserData(user);
  };

  const handleTitle = (title: string) => {
    setTitleData(title);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  return (
      <AuthContext.Provider
          value={{ user: userData, setUser: handleUser, isLoading, title: titleData, setTitle: handleTitle}}
      >
        {data?.children}
      </AuthContext.Provider>
  );
};

export default AuthProvider;
