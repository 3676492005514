import {
    Breadcrumb,
    Button, Card, Col, Dropdown, Flex, FloatButton, Image, Input, MenuProps,
    message, Modal, Row, Space,
    Spin, Tabs, TabsProps, Typography
} from "antd";
import React, {useEffect, useState} from "react";
import {API} from "../../constant";
import {getToken} from "../../helpers";
import {Link, useParams} from "react-router-dom";
import {Files, File, Attributes, FileDataMeta, FileCreate} from "./FileSystemModel";
import {useAuthContext} from "../../context/AuthContext";
import {FcFolder} from "react-icons/fc";
import { HomeOutlined, FileAddOutlined, PlusOutlined, FolderAddOutlined } from '@ant-design/icons';
import {AiOutlineEllipsis} from "react-icons/ai";

interface RenameData {
    isOpen: boolean,
    id?: bigint,
    name?: string
}

interface DeleteData {
    isOpen: boolean,
    id?: bigint
}

const FileSystem = () => {
    const userContext= useAuthContext();
    const { id } = useParams();
    const [files, setFiles] = useState<Files | undefined>(undefined);
    const [file, setFile] = useState<FileDataMeta | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalDirectory, setIsModalDirectory] = useState(false);
    const [directoryName, setDirectoryName] = useState('');
    const [isModalFile, setIsModalFile] = useState(false);
    const [renameData, setRenameData] = useState<RenameData>({isOpen: false});
    const [deleteData, setDeleteData] = useState<DeleteData>({isOpen: false});
    const [fileName, setFileName] = useState('');
    const [folders, setFolders] = useState<Folder[]>([]);

    const pageSize = 2048;

    interface Folder {
        id: number,
        name: string
    }

    const fetchFiles = async () => {
        setIsLoading(true);
        try {
            const url = (id === undefined) ?
                `${API}/file-systems?sort[0]=name:asc&filters[parent][$notNull]=&filters[$or][0][trash][$null]=true&filters[$or][1][trash][$eq]=f&pagination[pageSize]=${pageSize}&pagination[page]=1&locale[0]=en` :
                `${API}/file-systems?sort[0]=name:asc&filters[parent][$eq]=${id}&filters[$or][0][trash][$null]=true&filters[$or][1][trash][$eq]=f&pagination[pageSize]=${pageSize}&pagination[page]=1&locale[0]=en`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                }
            });
            const files: Files = await response.json() as Files;
            const filesWithKey: Files = {
                data: files.data.map((file: File) => {
                        return {
                            id: file.id,
                            key: file.id,
                            attributes: file.attributes
                        } as File
                    }),
                meta: files.meta
            }
            setFiles(filesWithKey ?? []);

            userContext.setTitle("Excalidraw diagrams");

            if (id !== undefined) {
                const responseFile = await fetch(`${API}/file-systems/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    }
                });
                const dataFile = await responseFile.json();
                setFile(dataFile)

                const responseFolders = await fetch(`${API}/folder/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    }
                });
                const dataFolders = await responseFolders.json();
                console.log("=====> " + JSON.stringify(dataFolders))
                setFolders(dataFolders)
            }

        } catch (error) {
            console.error(error);
            message.error("Error while loading !");
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false)
    };

    const showModalDirectory = () => {
        setIsModalDirectory(true);
    };
    const handleDirectoryOk = async () => {
        setIsModalDirectory(false);
        console.log("Dir="+directoryName);
        const curDate = new Date();
        const file: FileCreate = {
            data: {
                data: "[]",
                type: "DIR",
                name: directoryName,
                parent: id,
                createdAt: curDate,
                updatedAt: curDate,
                publishedAt: curDate
            } as Attributes
        };
        try {
            const response = await fetch(`${API}/file-systems`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(file)
            });
            const data: FileDataMeta = await response.json();
            await fetchFiles()
        } catch (error) {
            console.error(error);
            message.error("Error while updating file");
        } finally {
            setIsLoading(false);
        }
        setDirectoryName('')
    };
    const handleDirectoryCancel = () => {
        setIsModalDirectory(false);
        setDirectoryName('')
    };

    const showModalFile = () => {
        setIsModalFile(true);
    };
    const handleFileOk = async () => {
        setIsModalFile(false);
        console.log("File="+fileName)
        const curDate = new Date();
        const file: FileCreate = {
            data: {
                data: undefined,
                type: "FILE",
                name: fileName,
                extension: "excalidraw",
                parent: id,
                createdAt: curDate,
                updatedAt: curDate,
                publishedAt: curDate
            } as Attributes
        };
        try {
            const response = await fetch(`${API}/file-systems`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(file)
            });
            const data: FileDataMeta = await response.json();
            await fetchFiles()
        } catch (error) {
            console.error(error);
            message.error("Error while updating file");
        } finally {
            setIsLoading(false);
        }
        setFileName('')
    };
    const handleFileCancel = () => {
        setIsModalFile(false);
        setFileName('')
    };

    const handleRenameOk = async () => {
        setRenameData({...renameData, isOpen: false});
        console.log("Rename="+renameData.name)
        const fileOrFolderToRename: FileCreate = {
            data: {
                name: renameData.name
            } as Attributes
        };
        try {
            const response = await fetch(`${API}/file-systems/${renameData.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(fileOrFolderToRename)
            });
            const data: FileDataMeta = await response.json();
            await fetchFiles()
        } catch (error) {
            console.error(error);
            message.error("Error while updating fileOrFolderToRename");
        } finally {
            setIsLoading(false);
        }
    };
    const handleRenameCancel = () => {
        setRenameData({isOpen: false});
    };

    const handleDelete = async (id: bigint) => {
        setDeleteData({...deleteData, isOpen: false});
        console.log("Delete id="+id);
        const fileOrFolderToDelete: FileCreate = {
            data: {
                trash: true
            } as Attributes
        };
        try {
            const response = await fetch(`${API}/file-systems/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(fileOrFolderToDelete)
            });
            const data: FileDataMeta = await response.json();
            await fetchFiles()
        } catch (error) {
            console.error(error);
            message.error("Error while updating fileOrFolderToDelete");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFiles();
    }, []);

    if (isLoading) {
        return <Spin size="large" />;
    }

    const idParent = file?.data.attributes.parent

    const cardStyle: React.CSSProperties = {
        width: 245,
        height:200
    };

    const imgStyle: React.CSSProperties = {
        display: 'block',
        maxWidth: 245,
        maxHeight: 134,
        borderRadius: 8,
    };

    const items = (id: bigint, name: string) : MenuProps['items'] => [
        {
            key: '1',
            label: (
                <a onClick={() => setRenameData({ isOpen: true, id: id, name: name})}>
                    <>Rename</>
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => handleDelete(id)}>
                    <>Delete</>
                </a>
            ),
        },
    ];


    const CardComponent = (file: File) => {
        return <>
            <Card
                hoverable
                style={cardStyle}
                key={file.attributes.type+"-"+file.id}
                bodyStyle={{ padding: 0, overflow: 'hidden', cursor: 'default' }}
            >
                <Flex vertical align="flex-start" justify="space-between">
                    {(file.attributes.type==="DIR") ?
                        <Link reloadDocument={true} to={(file.attributes.type==="DIR")?"/fs/"+file.id:"/excalidraw-edit/"+file.id} >
                            <FcFolder size={134}/>
                        </Link> :
                        <Image
                            alt={file.attributes.name}
                            height={134}
                            src={`${API}/file-mgt/${file.id}/thumbnail`}
                            preview={{src: `${API}/file-mgt/${file.id}`}}
                            style={imgStyle}
                        />}
                    <Flex align="flex-start" justify="space-between" style={{ paddingTop: 16, paddingLeft: 8, width: "100%" }}>
                        <Row style={{width: "100%"}}>
                            <Col span={20}>
                                <Link reloadDocument={true} to={(file.attributes.type==="DIR")?"/fs/"+file.id:"/excalidraw-edit/"+file.id} >
                                    <Typography.Title level={4}>
                                        {file.attributes.name}
                                    </Typography.Title>
                                </Link>
                            </Col>
                            <Col span={4}>
                                <Dropdown menu={{ items : items(file.id, file.attributes.name) }} placement="bottomRight" trigger={['click']} arrow={{ pointAtCenter: true }}>
                                    <Button shape="circle">
                                        <AiOutlineEllipsis />
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                        {/*<Button type="primary" href="https://ant.design" target="_blank">*/}
                        {/*    Get Start*/}
                        {/*</Button>*/}
                    </Flex>
                </Flex>
            </Card>
        </>;
    }

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Folders',
            children: <Flex wrap="wrap" gap="middle" key="c1">
                {files?.data.filter(file => file.attributes.type === "DIR").map((file, index, arr) => {
                    return <Space key={file.attributes.type+"#-"+file.id}>{CardComponent(file)}</Space>
                })}
            </Flex>
            ,
        },
        {
            key: '2',
            label: 'Files',
            children: <Flex wrap="wrap" gap="middle" key="c2">
                {files?.data.filter(file => file.attributes.type !== "DIR").map((file, index, arr) => {
                    return <Space key={file.attributes.type+"-#-"+file.id}>{CardComponent(file)}</Space>
                })}
            </Flex>
            ,
        },
    ];


    return (
        <>
            <FloatButton.Group
                trigger="hover"
                type="primary"
                style={{ right: 24 }}
                icon={<PlusOutlined />}
            >
                <FloatButton icon={<FolderAddOutlined />} onClick={showModalDirectory}/>
                <FloatButton icon={<FileAddOutlined />} onClick={showModalFile}/>
            </FloatButton.Group>
            <Modal title="Rename" open={renameData.isOpen} onOk={handleRenameOk} onCancel={handleRenameCancel}>
                <Input placeholder="Name" value={renameData.name} onChange={e => setRenameData({ ...renameData, name: e.target.value })}/>
            </Modal>
            <Modal title="Create Directory" open={isModalDirectory} onOk={handleDirectoryOk} onCancel={handleDirectoryCancel}>
                <Input placeholder="Directory name" value={directoryName} onChange={e => setDirectoryName(e.target.value)}/>
            </Modal>
            <Modal title="Create new File" open={isModalFile} onOk={handleFileOk} onCancel={handleFileCancel}>
                <Input placeholder="File name" value={fileName} onChange={e => setFileName(e.target.value)}/>
            </Modal>
            <Space wrap>
                <Breadcrumb style={{paddingTop: 8}} items={[{
                    href: "/fs",
                    title: <HomeOutlined />,
                },...folders.map(folder => {
                    return {
                        href: "/fs/" + folder.id,
                        title: folder.name
                    };
                })]} />
            </Space>
            <Tabs
                defaultActiveKey="1"
                items={tabItems}
            />
        </>
    );
};

export default FileSystem;
