import { Button, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";
import {FcWorkflow} from "react-icons/fc";

const AppHeader = () => {
  const userContext= useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    userContext.setUser(undefined);
    navigate("/signin", { replace: true });
  };

  return (
    <Space className="header_space">
      <Button className="header_space_brand" href="/" type="link">
          <FcWorkflow size={64} />
      </Button>
        <Space><h1>{userContext.title}</h1></Space>
      <Space className="auth_buttons" >
        {userContext.user ? (
          <>
            <Button className="auth_button_login" href="/profile" type="link">
              {userContext.user?.username}
            </Button>
            <Button
              className="auth_button_signUp"
              type="primary"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button className="auth_button_login" href="/signin" type="link">
              Login
            </Button>
            <Button
              className="auth_button_signUp"
              href="/signup"
              type="primary"
            >
              SignUp
            </Button>
          </>
        )}
      </Space>
    </Space>
  );
};

export default AppHeader;
