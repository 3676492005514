import React from "react";
import { Col, Layout, Row } from "antd";
import AppHeader from "./components/Appheader/Appheader";
import AppRoutes from "./Routes";
const { Header, Content } = Layout;

const App = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row gutter={[0, 32]} style={{ minHeight: "100vh", rowGap: "0px"}}>
        <Col span={24} style={{height: "64px"}}>
          <Header>
            <AppHeader />
          </Header>
        </Col>
        <Col span={22} offset={1} style={{ minHeight: "80vh" }}>
          <Content style={{ minHeight: "100vh" }}>
            <AppRoutes />
          </Content>
        </Col>
      </Row>
    </Layout>
  );
};

export default App;
